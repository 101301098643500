import React from "react";
import * as PropTypes from "prop-types";
import {useStaticQuery, graphql, Link} from "gatsby";
import _ from "lodash";

import tutorials from "../data/tutorial";

function getMember(memberNode) {
    const {content: name} = memberNode.children.find(({name}) => name === 'name');
    const {content: anchorFile} = memberNode.children.find(({name}) => name === 'anchorfile');
    const {content: anchor} = memberNode.children.find(({name}) => name === 'anchor');

    return {
        name: name.replace(/&lt;/g, '<'),
        anchorFile,
        anchor
    };
}

function findCompoundMembers(compound) {
    return compound
        .filter(({name}) => name === 'member')
        .map(getMember);
}

function findMembers(className, compounds) {
    const compound = compounds.find(compound => {
        const {content} = compound.find(({name}) => name === 'name');
        return className === content;
    });

    return findCompoundMembers(compound);
}

function makeLink(local) {
    return `/ApiDoc/${local}`;
}

function getLinkMappings(compound, index, compounds) {
    const {content: className} = compound.find(({name}) => name === 'name');
    const {content: filename} = compound.find(({name}) => name === 'filename');
    const {content: base} = compound.find(({name}) => name === 'base') || {};

    const classMembers = findCompoundMembers(compound);
    const baseMembers = base ? findMembers(base, compounds) : [];
    const members = [...classMembers, ...baseMembers];

    const compoundFilename = filename.endsWith(".html") ? filename : `${filename}.html`;

    return [
        {text: className, to: makeLink(compoundFilename)},
        ...members.map(({name, anchorFile, anchor}) => ({
            text: `${className}::${name}`,
            to: `/ApiDoc/${anchorFile}#${anchor}`
        }))
    ];
}

const TutorialLink = ({full, step}) => {
    const tutorial = tutorials.find(({id}) => id === step);
    if (!tutorial) {
        throw new Error(`Can't find tutorial by step: ${step}`)
    }

    return (
        <Link to={tutorial.to}>{full ? 'Getting Started - ' : ''}{tutorial.name}</Link>
    );
};

const ApiLink = ({annotated, className = '', children, api = children}) => {
    const {allApiTagXml: {edges}} = useStaticQuery(graphql`
query ApiLinksQuery {
  allApiTagXml {
    edges {
      node {
        attributes {
          kind
        }
        name
        xmlChildren {
          name
          content
          attributes {
            kind
          }
          children {
            name
            content
          }
        }
      }
    }
  }
}
  `);

    if (annotated) {
        return (<a className={className} href={makeLink('annotated.html')}>{children}</a>);
    }

    const compounds = edges.map(edge => edge.node.xmlChildren);
    const links = _.flatMap(compounds, getLinkMappings);
    const search = api.trim().replace(/\s/g, "");
    const link = links.find(({text}) => text === search);

    if (!link) {
        throw new Error(`Can't find API link for ${api}`);
    }
    return (<a className={className} href={link.to}>{children}</a>);
};

ApiLink.propTypes = {
    children: PropTypes.node.isRequired
};

export {
    ApiLink,
    TutorialLink
};
