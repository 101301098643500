import React from "react";
import {CodeBlend} from "../components/code";
import {Callout} from "../components/alert";
import {ApiLink} from "../components/link";

const servers = [
    {
        name: 'Oracle',
        main: 'oracle/',
        connect: 'oracle/#connect',
        api: 'oracle/#api',
        client: 'SA_Oracle_Client',
        transaction: {
            SA_ReadUncommitted: 'READ COMMITTED',
            SA_ReadCommitted: 'READ COMMITTED',
            SA_RepeatableRead: 'SERIALIZABLE',
            SA_Serializable: 'SERIALIZABLE',
            SA_Snapshot: 'SERIALIZABLE'
        },
        lob: {
            fetch: [
                {dataType: 'LONG RAW', enumType: 'SA_dtLongBinary'},
                {dataType: 'LONG', enumType: 'SA_dtLongChar'},
                {dataType: 'BLOB, BFILE', enumType: 'SA_dtBLob'},
                {dataType: 'CLOB, CFILE', enumType: 'SA_dtCLob'},
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'SQLT_LBI'},
                {enumType: 'SA_dtLongChar', apiType: 'SQLT_LNG'},
                {enumType: 'SA_dtBLob', apiType: 'SQLT_BLOB'},
                {enumType: 'SA_dtCLob', apiType: 'SQLT_CLOB'}
            ]
        }
    },
    {
        name: 'SQL Server',
        main: 'mssql/',
        connect: 'mssql_odbc/#connect',
        api: 'mssql_odbc/#api',
        client: 'SA_SQLServer_Client'
    },
    {
        secondary: true,
        name: 'SQL Server (ODBC)',
        shortName: 'SQL Server',
        main: 'mssql_odbc/',
        connect: 'mssql_odbc/#connect',
        api: 'mssql_odbc/#api',
        client: 'SA_SQLServer_Client',
        transaction: {
            SA_ReadUncommitted: 'SQL_TXN_READ_UNCOMMITTED',
            SA_ReadCommitted: 'SQL_TXN_READ_COMMITTED',
            SA_RepeatableRead: 'SQL_TXN_REPEATABLE_READ',
            SA_Serializable: 'SQL_TXN_SERIALIZABLE',
            SA_Snapshot: 'SQL_TXN_SS_SNAPSHOT'
        },
        lob: {
            fetch: [
                {dataType: 'image', enumType: 'SA_dtLongBinary'},
                {dataType: 'text', enumType: 'SA_dtLongChar'},
                {dataType: 'varbinary(max)', enumType: 'SA_dtBLob'},
                {dataType: '[n]varchar(max)', enumType: 'SA_dtCLob'},
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'SQL_LONGVARBINARY'},
                {enumType: 'SA_dtLongChar', apiType: 'SQL_LONGVARCHAR'},
                {enumType: 'SA_dtBLob', apiType: 'SQL_VARBINARY'},
                {enumType: 'SA_dtCLob', apiType: 'SQL_VARCHAR'}
            ]
        }
    },
    {
        secondary: true,
        name: 'SQL Server (OLEDB)',
        shortName: 'SQL Server',
        main: 'mssql_oledb/',
        connect: 'mssql_oledb/#connect',
        api: 'mssql_oledb/#api',
        client: 'SA_SQLServer_Client',
        transaction: {
            SA_ReadUncommitted: 'ISOLATIONLEVEL_READUNCOMMITTED',
            SA_ReadCommitted: 'ISOLATIONLEVEL_READCOMMITTED',
            SA_RepeatableRead: 'ISOLATIONLEVEL_REPEATABLEREAD',
            SA_Serializable: 'ISOLATIONLEVEL_SERIALIZABLE',
            SA_Snapshot: 'ISOLATIONLEVEL_SNAPSHOT'
        },
        lob: {
            fetch: [
                {dataType: 'image', enumType: 'SA_dtLongBinary'},
                {dataType: 'text', enumType: 'SA_dtLongChar'},
                {dataType: 'varbinary(max)', enumType: 'SA_dtLongBinary'},
                {dataType: '[n]varchar(max)', enumType: 'SA_dtLongChar'},
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'DBTYPE_BYTES'},
                {enumType: 'SA_dtLongChar', apiType: 'DBTYPE_STR, DBTYPE_WSTR'},
                {enumType: 'SA_dtBLob', apiType: 'DBTYPE_BYTES'},
                {enumType: 'SA_dtCLob', apiType: 'DBTYPE_STR, DBTYPE_WSTR'}
            ]
        }
    },
    {
        name: 'DB2',
        main: 'db2/',
        connect: 'db2/#connect',
        api: 'db2/#api',
        client: 'SA_DB2_Client',
        transaction: {
            SA_ReadUncommitted: 'SQL_TXN_READ_UNCOMMITTED',
            SA_ReadCommitted: 'SQL_TXN_READ_COMMITTED',
            SA_RepeatableRead: 'SQL_TXN_REPEATABLE_READ',
            SA_Serializable: 'SQL_TXN_SERIALIZABLE',
            SA_Snapshot: 'SQL_TXN_SERIALIZABLE'
        },
        lob: {
            fetch: [
                {dataType: 'LONG VARBINARY', enumType: 'SA_dtLongBinary'},
                {dataType: 'LONG VARCHAR', enumType: 'SA_dtLongChar'},
                {dataType: 'BLOB', enumType: 'SA_dtBLob'},
                {dataType: 'GRAPHIC', enumType: 'SA_dtCLob'},
                {dataType: 'VARGRAPHIC', enumType: 'SA_dtCLob'},
                {dataType: 'LONG VARGRAPHIC', enumType: 'SA_dtCLob'},
                {dataType: 'CLOB', enumType: 'SA_dtCLob'},
                {dataType: 'DBCLOB', enumType: 'SA_dtCLob'},
                {dataType: 'XML', enumType: 'SA_dtCLob'},
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'SQL_LONGVARBINARY'},
                {enumType: 'SA_dtLongChar', apiType: 'SQL_LONGVARCHAR, SQL_UNICODE_LONGVARCHAR'},
                {enumType: 'SA_dtBLob', apiType: 'SQL_BLOB'},
                {enumType: 'SA_dtCLob', apiType: 'SQL_CLOB'}
            ]
        }
    },
    {
        name: 'Sybase',
        main: 'ase/',
        connect: 'ase/#connect',
        api: 'ase/#api',
        client: 'SA_Sybase_Client',
        transaction: {
            SA_ReadUncommitted: 'read uncommitted',
            SA_ReadCommitted: 'read committed',
            SA_RepeatableRead: 'repeatable read',
            SA_Serializable: 'serializable',
            SA_Snapshot: 'transaction snapshot'
        },
        lob: {
            fetch: [
                {dataType: 'image', enumType: 'SA_dtLongBinary'},
                {dataType: 'text', enumType: 'SA_dtLongChar'},
                {dataType: 'unitext', enumType: 'SA_dtLongChar'}
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'CS_IMAGE_TYPE'},
                {enumType: 'SA_dtLongChar', apiType: 'CS_TEXT_TYPE'},
                {enumType: 'SA_dtBLob', apiType: 'CS_IMAGE_TYPE'},
                {enumType: 'SA_dtCLob', apiType: 'CS_TEXT_TYPE'}
            ]
        }
    },
    {
        name: 'MySQL',
        shortName: 'MySQL',
        main: 'mysql/',
        connect: 'mysql/#connect',
        api: 'mysql/#api',
        client: 'SA_MySQL_Client',
        transaction: {
            SA_ReadUncommitted: 'SQL_TXN_READ_UNCOMMITTED',
            SA_ReadCommitted: 'SQL_TXN_READ_COMMITTED',
            SA_RepeatableRead: 'SQL_TXN_REPEATABLE_READ',
            SA_Serializable: 'SQL_TXN_SERIALIZABLE',
            SA_Snapshot: 'SQL_TXN_SERIALIZABLE'
        },
        lob: {
            fetch: [
                {dataType: 'BLOB', enumType: 'SA_dtLongBinary'},
                {dataType: 'TEXT', enumType: 'SA_dtLongChar'}
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'MYSQL_TYPE_BLOB'},
                {enumType: 'SA_dtLongChar', apiType: 'MYSQL_TYPE_STRING'},
                {enumType: 'SA_dtBLob', apiType: 'MYSQL_TYPE_BLOB'},
                {enumType: 'SA_dtCLob', apiType: 'MYSQL_TYPE_STRING'}
            ]
        }
    },
    {
        name: 'PostgreSQL',
        main: 'pgsql/',
        connect: 'pgsql/#connect',
        api: 'pgsql/#api',
        client: 'SA_PostgreSQL_Client',
        transaction: {
            SA_ReadUncommitted: 'READ UNCOMMITTED',
            SA_ReadCommitted: 'READ COMMITTED',
            SA_RepeatableRead: 'REPEATABLE READ',
            SA_Serializable: 'SERIALIZABLE',
            SA_Snapshot: 'SERIALIZABLE'
        },
        lob: {
            fetch: [
                {dataType: 'BYTEA', enumType: 'SA_dtLongBinary'},
                {dataType: 'TEXT', enumType: 'SA_dtLongChar'},
                {
                    dataType: 'OID',
                    enumType: 'SA_dtBLob',
                    comment:
                        <>
                            only if {' '}<CodeBlend>'OidTypeInterpretation'</CodeBlend>{' '} command option set to
                            {' '}<CodeBlend>'LargeObject'</CodeBlend>
                        </>
                }
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'HEX string of the binary data'},
                {enumType: 'SA_dtLongChar', apiType: 'escaped string'},
                {enumType: 'SA_dtBLob', apiType: 'OID', comment: 'Oid of an object that contains the binary data'},
                {enumType: 'SA_dtCLob', apiType: 'OID', comment: 'Oid of an object that contains the text data'}
            ]
        }
    },
    {
        name: 'SQLite',
        main: 'sqlite/',
        connect: 'sqlite/#connect',
        api: 'sqlite/#api',
        client: 'SA_SQLite_Client',
        transaction:
            <>
                <p>
                    SQL-92 defines four isolation levels, but SQLite does not support the concept of SQL-92 levels,
                    so calling {' '}<ApiLink>SAConnection::setIsolationLevel</ApiLink>{' '} has no effect for SQLite.
                </p>
                <p>
                    Instead, SQLite offers three different modes for transactions:
                    {' '}<CodeBlend>"DEFERRED"</CodeBlend>, {' '}<CodeBlend>"EXCLUSIVE"</CodeBlend>{' '} and
                    {' '}<CodeBlend>"IMMEDIATE"</CodeBlend>. You can control which one is used with
                    {' '}<code>"SQLiteTransactionType"</code>{' '} connection-scoped <a href="#options">option</a>.
                </p>
                <Callout heading="SQLite Docs">
                    A good overview of how SQLite implements transactions is available here:
                    {' '}
                    <a className="alert-link" href="https://sqlite.org/lang_transaction.html">
                        https://sqlite.org/lang_transaction.html
                    </a>
                </Callout>
            </>,
        lob: {
            fetch: [
                /*
                No DB column types are mapped into SA_dtLong* or SA_dt*Lob.
                */
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'SQLITE_BLOB'},
                {enumType: 'SA_dtLongChar', apiType: 'SQLITE_TEXT'},
                {enumType: 'SA_dtBLob', apiType: 'SQLITE_BLOB'},
                {enumType: 'SA_dtCLob', apiType: 'SQLITE_TEXT'}
            ]
        }
    },
    {
        name: 'Informix',
        main: 'informix/',
        connect: 'informix/#connect',
        api: 'informix/#api',
        client: 'SA_Informix_Client',
        transaction: {
            SA_ReadUncommitted: 'SQL_TXN_READ_UNCOMMITTED',
            SA_ReadCommitted: 'SQL_TXN_READ_COMMITTED',
            SA_RepeatableRead: 'SQL_TXN_REPEATABLE_READ',
            SA_Serializable: 'SQL_TXN_SERIALIZABLE',
            SA_Snapshot: 'SQL_TXN_SERIALIZABLE'
        },
        lob: {
            fetch: [
                {dataType: 'BYTE, BLOB', enumType: 'SA_dtLongBinary'},
                {dataType: 'TEXT, CLOB', enumType: 'SA_dtLongChar'},
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'SQL_LONGVARBINARY'},
                {enumType: 'SA_dtLongChar', apiType: 'SQL_LONGVARCHAR, SQL_WLONGVARCHAR | SQL_UNICODE_LONGVARCHAR'},
                {enumType: 'SA_dtBLob', apiType: 'SQL_LONGVARBINARY'},
                {enumType: 'SA_dtCLob', apiType: 'SQL_LONGVARCHAR, SQL_WLONGVARCHAR | SQL_UNICODE_LONGVARCHAR'}
            ]
        }
    },
    {
        name: 'InterBase / Firebird',
        shortName: 'InterBase',
        main: 'ibase/',
        connect: 'ibase/#connect',
        api: 'ibase/#api',
        client: 'SA_InterBase_Client',
        transaction: {
            SA_ReadUncommitted: 'isc_tpb_read_committed + isc_tpb_rec_version',
            SA_ReadCommitted: 'isc_tpb_read_committed + isc_tpb_no_rec_version',
            SA_RepeatableRead: 'isc_tpb_consistency',
            SA_Serializable: 'isc_tpb_concurrency',
            SA_Snapshot: 'isc_tpb_concurrency'
        },
        lob: {
            fetch: [
                {dataType: 'ARRAY', enumType: 'SA_dtBLob'},
                {dataType: 'BLOB (sub-type 0)', enumType: 'SA_dtBLob'},
                {dataType: 'BLOB (sub-type 1)', enumType: 'SA_dtCLob'},
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'SQL_BLOB'},
                {enumType: 'SA_dtLongChar', apiType: 'SQL_BLOB'},
                {enumType: 'SA_dtBLob', apiType: 'SQL_BLOB'},
                {enumType: 'SA_dtCLob', apiType: 'SQL_BLOB'}
            ]
        }
    },
    {
        name: 'SQLBase',
        main: 'sqlbase/',
        connect: 'sqlbase/#connect',
        api: 'sqlbase/#api',
        client: 'SA_SQLBase_Client',
        transaction: {
            SA_ReadUncommitted: `SQLILRL ('RL')`,
            SA_ReadCommitted: `SQLILCS ('CS')`,
            SA_RepeatableRead: `SQLILRR ('RR')`,
            SA_Serializable: `SQLILRO ('RO')`,
            SA_Snapshot: `SQLILRO ('RO')`
        },
        lob: {
            fetch: [
                {dataType: 'LONG BINARY', enumType: 'SA_dtLongBinary'},
                {dataType: 'LONG VAR GRAPHIC', enumType: 'SA_dtLongBinary'},
                {dataType: 'LONG VARCHAR', enumType: 'SA_dtLongChar'},
                {dataType: 'CHAR > 254', enumType: 'SA_dtLongChar'},
                {dataType: 'VARCHAR > 254', enumType: 'SA_dtLongChar'}
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'SQLPLBI (long binary buffer)'},
                {enumType: 'SA_dtLongChar', apiType: 'SQLPLON (long text string)'},
                {enumType: 'SA_dtBLob', apiType: 'SQLPLBI (long binary buffer)'},
                {enumType: 'SA_dtCLob', apiType: 'SQLPLON (long text string)'}
            ]
        }
    },
    {
        name: 'SQL Anywhere',
        main: 'asa/',
        connect: 'asa/#connect',
        api: 'asa/#api',
        client: 'SA_SQLAnywhere_Client',
        transaction: {
            SA_ReadUncommitted: `SET TEMPORARY OPTION isolation_level = 0`,
            SA_ReadCommitted: `SET TEMPORARY OPTION isolation_level = 1`,
            SA_RepeatableRead: `SET TEMPORARY OPTION isolation_level = 2`,
            SA_Serializable: `SET TEMPORARY OPTION isolation_level = 3`,
            SA_Snapshot: `SET TEMPORARY OPTION isolation_level = 3`
        },
        lob: {
            fetch: [
                {dataType: 'LONG BINARY', enumType: 'SA_dtLongBinary'},
                {dataType: 'IMAGE', enumType: 'SA_dtLongBinary'},
                {dataType: 'LONG VARCHAR', enumType: 'SA_dtLongChar'},
                {dataType: 'LONG NVARCHAR', enumType: 'SA_dtLongChar'},
                {dataType: 'TEXT', enumType: 'SA_dtLongChar'},
                {dataType: 'NTEXT', enumType: 'SA_dtLongChar'},
                {dataType: 'XML', enumType: 'SA_dtLongChar'}
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'A_BINARY'},
                {enumType: 'SA_dtLongChar', apiType: 'A_STRING'},
                {enumType: 'SA_dtBLob', apiType: 'A_BINARY'},
                {enumType: 'SA_dtCLob', apiType: 'A_STRING'}
            ]
        }
    },
    {
        name: 'ODBC',
        main: 'odbc/',
        connect: 'odbc/#connect',
        api: 'odbc/#api',
        client: 'SA_ODBC_Client',
        transaction: {
            SA_ReadUncommitted: 'SQL_TXN_READ_UNCOMMITTED',
            SA_ReadCommitted: 'SQL_TXN_READ_COMMITTED',
            SA_RepeatableRead: 'SQL_TXN_REPEATABLE_READ',
            SA_Serializable: 'SQL_TXN_SERIALIZABLE',
            SA_Snapshot: 'SQL_TXN_SERIALIZABLE'
        },
        lob: {
            fetch: [
                {dataType: 'SQL_BINARY', enumType: 'SA_dtLongBinary'},
                {dataType: 'SQL_VARBINARY with ColumnSize = 0', enumType: 'SA_dtLongBinary'},
                {dataType: 'SQL_CHAR', enumType: 'SA_dtLongChar'},
                {dataType: 'SQL_VARCHAR with ColumnSize = 0', enumType: 'SA_dtLongChar'},
                {dataType: 'SQL_LONGVARBINARY', enumType: 'SA_dtLongBinary'},
                {dataType: 'SQL_LONGVARCHAR', enumType: 'SA_dtLongChar'},
                {dataType: 'SQL_WLONGVARCHAR', enumType: 'SA_dtLongChar'}
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'SQL_LONGVARBINARY'},
                {enumType: 'SA_dtLongChar', apiType: 'SQL_LONGVARCHAR, SQL_UNICODE_LONGVARCHAR'},
                {enumType: 'SA_dtBLob', apiType: 'SQL_LONGVARBINARY'},
                {enumType: 'SA_dtCLob', apiType: 'SQL_LONGVARCHAR, SQL_UNICODE_LONGVARCHAR'}
            ]
        }
    },
    {
        name: 'MariaDB',
        shortName: 'MariaDB',
        main: 'mariadb/',
        connect: 'mariadb/#connect',
        api: 'mariadb/#api',
        client: 'SA_MariaDB_Client',
        transaction: {
            SA_ReadUncommitted: 'SQL_TXN_READ_UNCOMMITTED',
            SA_ReadCommitted: 'SQL_TXN_READ_COMMITTED',
            SA_RepeatableRead: 'SQL_TXN_REPEATABLE_READ',
            SA_Serializable: 'SQL_TXN_SERIALIZABLE',
            SA_Snapshot: 'SQL_TXN_SERIALIZABLE'
        },
        lob: {
            fetch: [
                {dataType: 'BLOB', enumType: 'SA_dtLongBinary'},
                {dataType: 'TEXT', enumType: 'SA_dtLongChar'}
            ],
            bind: [
                {enumType: 'SA_dtLongBinary', apiType: 'MYSQL_TYPE_BLOB'},
                {enumType: 'SA_dtLongChar', apiType: 'MYSQL_TYPE_STRING'},
                {enumType: 'SA_dtBLob', apiType: 'MYSQL_TYPE_BLOB'},
                {enumType: 'SA_dtCLob', apiType: 'MYSQL_TYPE_STRING'}
            ]
        }
    }
];

export {
    servers
};
