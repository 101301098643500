import React from "react";
import {Link} from "gatsby";

import {ServerBadge} from "./badge";
import {servers} from "../data/servers";

const LinkComponent = ({
                           server,
                           linkClass = '',
                           linkType = 'main',
                           serverContainer: ServerContainer = ({children}) => children,
                           linkContainer: LinkContainer = ({children}) => children,
                           children
                       }) => {
    const {name} = server;
    const href = `/ApiDoc/${server[linkType]}`;

    return (
        <LinkContainer key={name}>
            <Link className={linkClass} to={href}>
                <ServerContainer>{children || name}</ServerContainer>
            </Link>
        </LinkContainer>
    )
};

const ServerLink = ({linkType, name: explicitName, children: displayName}) => {
    const server =
        (explicitName && servers.find(({name}) => name === (explicitName))) ||
        servers.find(({name}) => name === (displayName));
    if (!server) {
        throw new Error(`Can't find server by name: ${displayName}`);
    }

    return (
        <LinkComponent server={server} linkType={linkType}>{displayName}</LinkComponent>
    );
};

const ServerLinks = ({
                         linkClass,
                         linkType,
                         serverContainer,
                         linkContainer
                     }) => (
    servers.filter(server => !server.secondary).map(server => {
        const {name} = server;

        return [
            ' ',
            <LinkComponent key={name}
                           server={server}
                           linkClass={linkClass}
                           linkType={linkType}
                           serverContainer={serverContainer}
                           linkContainer={linkContainer}
            />
        ];
    })
);

const ServerBadgeList = ({linkClass, linkType, badgeBorder = 'info'}) => (

    <ServerLinks linkClass={linkClass}
                 linkType={linkType}
                 serverContainer={({children}) => <ServerBadge border={badgeBorder}>{children}</ServerBadge>}/>
);

export {
    ServerLink,
    ServerLinks,
    ServerBadgeList
};
